






























































































































@import "../../assets/css/copyingDetail";

.header {
	color: #FFFFFF;

	span {
		cursor: pointer;
	}
}
