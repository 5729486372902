.left {
	width: 280px;
	height: calc(100vh - 240px);
}

.common {
	float: left;
	margin-top: 18px;
	padding: 20px;
	border-radius: 8px;
	border: 1px solid #0067A6;
	color: #ffffff;
	margin-bottom: 16px;

	h3 {
		color: #0067A6;
	}
}

.right ::v-deep {
	width: calc(100% - 390px);
	margin-left: 18px;

	.el-table {
		margin-top: 10px;
	}
	.el-table__body-wrapper, .el-table__footer-wrapper, .el-table__header-wrapper{
		background: #212844;
	}
	.el-table__fixed-right::before, .el-table__fixed::before {
		display: none;
	}
	.el-table td {
		padding: 0;
		font-weight: 400;
		color: rgba(255, 255, 255, 0.5);
		height: 43px;
	}
	.el-table th, .el-table tr {
		background: #212844;
	}
	.el-table--enable-row-hover .el-table__body tr:hover > td,
	.el-table__body tr.hover-row.current-row > td,
	.el-table__body tr.hover-row.el-table__row--striped.current-row > td,
	.el-table__body tr.hover-row.el-table__row--striped > td,
	.el-table__body tr.hover-row > td {
		background: none;
	}

}

.pagination ::v-deep {
	padding: 10px 0;
	text-align: center;

	li {
		color: #666666;
		background: transparent;
	}

	.el-pagination__total {
		margin-left: 10px;
		color: #FFFFFF;
	}

	.btn-next, .btn-prev, li.active {
		color: #FFFFFF;
		background-color: transparent;
	}
}
